// See https://github.com/mui-org/material-ui/tree/master/examples/gatsby
import React from "react";
import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import getPageContext from "./getPageContext";
import Hidden from "@material-ui/core/Hidden";
import theme from "./theme";
function withRoot(Component) {
  class WithRoot extends React.Component {
    constructor(props) {
      super(props);
      this.muiPageContext = getPageContext();
    }

    componentDidMount() {
      const jssStyles = document.querySelector("#jss-server-side");
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles);
      }
    }

    render() {
      return (
        <StylesProvider
          generateClassName={this.muiPageContext.generateClassName}
          sheetsManager={this.muiPageContext.sheetsManager}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Hidden implementation='css'>
              <Component {...this.props} />
            </Hidden>
          </ThemeProvider>
        </StylesProvider>
      );
    }
  }

  return WithRoot;
}

export default withRoot;
