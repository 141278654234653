import React from "react";
import styled from "styled-components";

const NavigationWrapper = styled.nav`
  padding-top: 100px;
  display: flex;
  list-style: none;
  justify-content: flex-start;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background: #fff;
  height: 100%;
  flex-direction: column;
  transition: 0.5s ease-in-out;
  transform-origin: left;
  transform: translateX(150%) rotate(-10deg);
  opacity: 0;

  &.open {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }

  span {
    text-decoration: none;
    color: #000;
    height: 60px;
    display: block;
    line-height: 60px;
    text-align: center;
    transition: 0.3s ease-in-out;
    font-size: 30px;
    font-weight: 600;
    &:last-child {
      margin-bottom: 0;
    }
    &:focus,
    &:hover {
      background: #ccc;
    }
  }
`;

const Header = () => (
  <NavigationWrapper className='mobile-nav'>
    <span className='home'>Home</span>
    <span className='about'>Über uns</span>
    <span className='menu'>Menü</span>
    <span className='time'>Öffnungszeiten</span>
    <span className='locations'>Standort</span>
  </NavigationWrapper>
);

export default Header;
