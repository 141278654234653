import React from "react";
import Header from "./Header";
import withStyles from "@material-ui/styles/withStyles";
import styled from "styled-components";

const styles = {
  container: {
    marginTop: 94,
  },
  contentBox: {
    maxWidth: "calc(1136px - 60px)",
    width: "calc(100% - 60px)",
  },
  title: {
    textAlign: "center",
  },
};

const Page = styled.div`
  animation-name: opacityTran;
  animation-timing-function: linear;
  animation-duration: 1s;
  @keyframes opacityTran {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const Component = ({ children }) => {
  return (
    <Page>
      <Header />
      {children}
    </Page>
  );
};

export default withStyles(styles)(Component);
